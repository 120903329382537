import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import AppConfig from '../services/app-config';
import BackgroundImage from '../components/Cityline';
// Material UI imports
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import HomeIcon from '@mui/icons-material/Home';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Avatar from '@mui/material/Avatar';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import ShareIcon from '@mui/icons-material/Share';
import Chip from '@mui/material/Chip';
import { QRCodeSVG } from 'qrcode.react';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import KeyIcon from '@mui/icons-material/Key';

import AuthService from '../services/auth.js';
import IconButton from '@mui/material/IconButton';
import BadgeIcon from '@mui/icons-material/Badge';

import DeleteIcon from '@mui/icons-material/Delete';
import LoginIcon from '@mui/icons-material/Login';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const AirbnbReservation = () => {
  const { reservationId } = useParams();
  const [language, setLanguage] = useState('es');
  const [reservation, setReservation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openRules, setOpenRules] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [dialogAction, setDialogAction] = useState('');
  const [dialogLoading, setDialogLoading] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [openQrModal, setOpenQrModal] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editField, setEditField] = useState('');
  const [editValue, setEditValue] = useState('');
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareMessage, setShareMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_MGO_API_URL;
  const userCors = process.env.REACT_APP_USE_CORS;
  if (userCors === "true") {
    axios.defaults.withCredentials = true
  }

  const handleCopyLink = () => {
    const link = `${window.location.origin}/guestform?id=${reservationId}`;
    navigator.clipboard.writeText(link).then(() => {
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 2000);
    });
  };

  const fetchReservation = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/airbnb/get/${reservationId}`);
      console.log(response.data.data.form_data);
      response.data.data["guest_name"] = response.data.data.form_data["1_guest"]["1_name"]["value"].split(' ')[0];
      setReservation(response.data.data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch reservation details');
      console.error('Error fetching reservation:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (AuthService.getCurrentUserRole() === "habitante" && AppConfig.get("unit_mode") !== "airbnb") {
      navigate("/");
    }    

    if (reservationId) {
      fetchReservation();
    }
  }, [reservationId, apiUrl]);

  const handleOpenRules = () => setOpenRules(true);
  const handleCloseRules = () => setOpenRules(false);

  const handleOpenConfirmDialog = (action) => {
    setDialogAction(action);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setDialogLoading(false);
  };

  const handleConfirmAction = () => {
    if (dialogAction === 'delete') {
      handleDeleteReservation(reservationId);
    } else if (dialogAction === 'checkin') {
      handleCheckIn(reservationId);
    }
  };

  const handleDeleteReservation = async (reservationId) => {
    setDialogLoading(true);
    try {
      try {
        await axios.post(`${apiUrl}/visit/invalidate`, { access_code: reservation.visit_code });
      } catch (error) {
        console.error('Error invalidating access code:', error);
      }
      await axios.post(`${apiUrl}/airbnb/delete/reservation/${reservationId}`);
      navigate("/airbnb");
    } catch (error) {
      console.error('Error deleting reservation:', error);
    } finally {
      setDialogLoading(false);
      setOpenConfirmDialog(false);
    }
  };

  const handleCheckIn = async (reservationId) => {
    setOpenBackdrop(true);
    try {
      await axios.post(`${apiUrl}/visit/checkin`, { access_code: reservation.visit_code });
      await axios.post(`${apiUrl}/airbnb/checkin/${reservationId}`);
      navigate("/airbnb");
    } catch (error) {
      console.error('Error checking in reservation:', error);
    } finally {
      setOpenBackdrop(false);
      setOpenConfirmDialog(false);
    }
  };

  const handleOpenEdit = (field, value) => {
    setEditField(field);
    if (field === 'guest_number') {
      setEditValue(value.toString());
    } else {
      const date = new Date(value * 1000);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      setEditValue(`${year}-${month}-${day}T${hours}:${minutes}`);
    }
    setOpenEditModal(true);
  };

  const handleCloseEdit = () => {
    setOpenEditModal(false);
    setEditField('');
    setEditValue('');
  };

  const handleSaveEdit = async () => {
    setOpenBackdrop(true);
    try {
      const payload = {
        id: reservationId,
        field: editField,
        value: editField === 'guest_number' ? parseInt(editValue) : Math.floor(new Date(editValue).getTime() / 1000)
      };
      await axios.post(`${apiUrl}/airbnb/reservation/update`, payload);
      await fetchReservation();
      handleCloseEdit();
    } catch (error) {
      console.error('Error updating reservation:', error);
    } finally {
      await fetchReservation();
      setOpenBackdrop(false);
      handleCloseEdit();
    }
  };

  const handleShareModalOpen = () => {
    setShareMessage(`Hola ${reservation.guest_name}, aquí tienes el código de acceso de la cerradura inteligente para la puerta de ${AppConfig.getVersedUnitType()}. Este código será válido durante toda tu estancia en ${AuthService.getCurrentUserOrg()}. Recuerda que para el check-in aún debes presentarte junto con los demás huéspedes en el lobby.\n\n🏠 ${AppConfig.get("unit_type")}: ${reservation.unit}\n🔑 Código: ${reservation.ttlock_code}\n\nSi el teclado está apagado, pasa suavemente la mano sobre la cerradura inteligente, ingresa el código ${reservation.ttlock_code} y, por último, presiona ☑️.`);
    setShareModalOpen(true);
  };

  const handleCopyMessage = () => {
    navigator.clipboard.writeText(shareMessage);
    setSnackbarOpen(true);
    setShareModalOpen(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  if (loading) {
    return (
      <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  if (!reservation) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Alert severity="info">No reservation found</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ pt: 2 }}>
      <Backdrop open={openBackdrop} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ mb: 4, p: 2 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src="/airbnb-logo.png" alt="Airbnb" style={{ width: 24, height: 24, marginRight: 8 }} />
              <Typography variant="h6" component="div">
                Reservacion
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                <HomeIcon sx={{ mr: 0.4, fontSize: 'medium' }} />
                {AppConfig.get('unit_type')}
              </Typography>
              <Typography variant="body1" sx={{ml: 2}}>
                {reservation.unit}
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ mb: 0.5, mt: 2 }}>
              <Typography variant="subtitle2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                <AccessTimeFilledIcon sx={{ mr: 0.4, fontSize: 'medium' }} />
                Check-in
                {AuthService.userRoleIncludes(["habitante"]) && (
                  <IconButton size="small" onClick={() => handleOpenEdit('checkin', reservation.checkin)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                )}
              </Typography>
              <Typography variant="body1" sx={{ml: 2}}>
                {AppConfig.formatFullDate(reservation.checkin)}
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                <AccessTimeFilledIcon sx={{ mr: 0.4, fontSize: 'medium' }} />
                Check-out
                {AuthService.userRoleIncludes(["habitante"]) && (
                  <IconButton size="small" onClick={() => handleOpenEdit('expires', reservation.expires)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                )}
              </Typography>
              <Typography variant="body1" sx={{ml: 2}}>
                {AppConfig.formatFullDate(reservation.expires)}
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ mb: 1 , mt: 2}}>
              <Typography variant="subtitle2" color="text.secondary">
              <AssignmentIcon sx={{ mr: 0.4, fontSize: 'medium' }} />
                Formulario
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="body1">
                  {reservation.info_submitted ? <CheckCircleIcon color="secondary" sx={{ mr: 0.4, fontSize: 'medium' }}/> : <PendingIcon sx={{ mr: 0.4, fontSize: 'medium' }}/>}
                  {reservation.info_submitted ? "Completado" : "No completado"}
                </Typography>
                <IconButton
                  color="primary"
                  onClick={() => setOpenQrModal(true)}
                  sx={{ mr: 1 }}
                >
                  {AuthService.getCurrentUserRole() === "habitante" ? <ShareIcon /> : <QrCodeIcon />}
                </IconButton>
              </Box>
              
            </Box>
            
            <Button 
              variant="outlined"
              color="secondary" 
              size="small" 
              onClick={handleOpenRules}
              sx={{ mt: 1, mb: 2 }}
            >
              {reservation.info_submitted ? "Ver Reglamento Aceptado" : "Ver Reglamento"}
            </Button>
            <Divider />
          </Grid>

          
          <Grid item xs={12} md={6}>
            {reservation.ttlock_code && (
              <>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                    <KeyIcon sx={{ mr: 1 }} />
                    Código de Acceso
                  </Typography>
                  <Box sx={{ my: 1.5, p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 2 }}>
                    <Typography variant="h5" align="center">
                      {reservation.ttlock_code}
                    </Typography>
                  </Box>
                  <Stack direction="row" spacing={1} sx={{ mt: 1, justifyContent: 'center' }}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => navigator.clipboard.writeText(reservation.ttlock_code)}
                      size="small"
                    >
                      Copiar Código
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleShareModalOpen}
                      size="small"
                    >
                      Compartir Mensaje
                    </Button>
                  </Stack>
                  <Typography variant="caption" color="text.secondary" sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                    Integración proveída por TTLock
                    <img src="/ttlock-logo.png" alt="TTLock" style={{ width: 16, height: 16, marginLeft: 4 }} />
                  </Typography>
                </Box>
                <Divider />
              </>
            )}
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6" gutterBottom sx={{mb: 2}}>
                {reservation.guest_number} {reservation.guest_number > 1 ? "Huespedes" : "Huesped"}
                {AuthService.userRoleIncludes(["habitante"]) && (
                  <IconButton size="small" onClick={() => handleOpenEdit('guest_number', reservation.guest_number)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                )}
              </Typography>

              {reservation.form_data && (() => {
                const additionalGuests = [];
                for (let i = 1; i <= 20; i++) {
                  const guestKey = `${i}_guest`;
                  if (reservation.form_data[guestKey]) {
                    additionalGuests.push(
                      <Box key={`guest-container-${i}`}>
                        <Box sx={{ my: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>
                              {reservation.form_data[guestKey]["1_name"].value ? reservation.form_data[guestKey]["1_name"].value.charAt(0).toUpperCase() : 'H'}
                            </Avatar>
                            <Box>
                              <Typography variant="subtitle2" color="text.secondary">
                                {guestKey === "1_guest" ? "Huesped Principal" : `Huesped ${i}`}
                              </Typography>
                              <Typography variant="body1">
                                {reservation.form_data[guestKey]["1_name"].value}
                              </Typography>
                            </Box>
                          </Box>
                          <IconButton onClick={() => {
                            if (reservation.form_data[guestKey]["3_id_picture"].value) {
                              setCurrentImage(`${apiUrl}/img/airbnb_${reservation.form_data[guestKey]["3_id_picture"].value}`);
                              setOpenImageModal(true);
                            }
                          }}>
                            <BadgeIcon color={reservation.form_data[guestKey]["3_id_picture"].submitted ? "secondary" : "default"} />
                          </IconButton>
                        </Box>
                        <Divider />
                      </Box>
                    );
                  } else {
                    break; // Stop iteration if guest not found
                  }
                }
                return additionalGuests;
              })()}

              <Box display="flex" justifyContent="center" width="100%">
                {reservation.guest_checked_in && (
                  <Chip 
                    icon={<CheckCircleIcon style={{ color: 'green' }} />}
                    label={<Typography variant='body1'>{reservation.guest_number > 1 ? "Huespedes Ingresados" : "Huesped Ingresado"}</Typography>}
                    color="default" 
                    sx={{ mt: 4, p: 2 }} 
                  />
                )}
              </Box>
            </Box>
            {reservation.ttlock_code && (
              <Dialog
                open={shareModalOpen}
                onClose={() => setShareModalOpen(false)}
                maxWidth="sm"
                fullWidth
              >
                <DialogTitle>Compartir Código de Acceso</DialogTitle>
                <DialogContent>
                  <TextField
                    fullWidth
                    multiline
                    rows={12}
                    value={shareMessage}
                    onChange={(e) => setShareMessage(e.target.value)}
                    variant="outlined"
                    sx={{ mt: 2 }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setShareModalOpen(false)}>
                    Cerrar
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<ContentCopyIcon />}
                    onClick={handleCopyMessage}
                  >
                    Copiar Mensaje
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </Grid>
          
          <Grid item xs={12}>
            {AuthService.userRoleIncludes(["habitante"]) && reservation.expires > Date.now() / 1000 && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<DeleteIcon />}
                  onClick={() => handleOpenConfirmDialog('delete')}
                  sx={{ mt: 2 }}
                >
                  Eliminar Reservación
                </Button>
              </>
            )}
            {AuthService.userRoleIncludes(["admin","receptor"]) && (
              <Box>
                <Typography variant="caption" color="text.secondary" sx={{ mt: 2, display: 'block', textAlign: 'center' }}>
                  {reservation.info_submitted 
                    ? reservation.guest_checked_in ? "Los huespedes ya han completado su ingreso" : "Haz click para registar el ingreso de los huespedes"
                    : "El formulario no ha sido completado, solicita a los huespedes llenarlo."}
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mt: 0.5 }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    startIcon={<LoginIcon />}
                    onClick={() => handleOpenConfirmDialog('checkin')}
                    disabled={!reservation.info_submitted || reservation.guest_checked_in}
                  >
                    Dar Ingreso a Huespedes
                  </Button>
                  
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>

      <Dialog
        open={openRules}
        onClose={handleCloseRules}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Reglamento</DialogTitle>
        <DialogContent>
          <List>
            {reservation.form_data.guest_rules.rules.es.rules.map((rule, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemIcon>
                    {reservation.form_data.guest_rules.accepted[index] ? (
                      <CheckIcon color="primary" />
                    ) : (
                      <PendingIcon color="default" />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={rule} />
                </ListItem>
                {index < reservation.form_data.guest_rules.rules[language].rules.length - 1 && (
                  <Divider component="li" />
                )}
              </React.Fragment>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRules}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogAction === 'delete' ? '¿Eliminar reservación?' : '¿Dar ingreso a huespedes?'}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {dialogAction === 'delete' 
              ? '¿Estás seguro que deseas eliminar esta reservación? Esta acción no se puede deshacer.'
              : '¿Estás seguro que deseas dar ingreso a los huespedes? Esta acción no se puede deshacer.'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} disabled={dialogLoading}>Cancelar</Button>
          <Button 
            onClick={handleConfirmAction} 
            color={dialogAction === 'delete' ? 'error' : 'primary'}
            disabled={dialogLoading}
            startIcon={dialogLoading ? <CircularProgress size={20} /> : null}
          >
            {dialogLoading ? 'Procesando...' : (dialogAction === 'delete' ? 'Eliminar' : 'Confirmar')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openImageModal}
        onClose={() => setOpenImageModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          {currentImage && (
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <img 
                src={currentImage} 
                alt="ID Document" 
                style={{ 
                  maxWidth: '100%', 
                  maxHeight: '80vh', 
                  objectFit: 'contain' 
                }} 
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenImageModal(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openQrModal}
        onClose={() => setOpenQrModal(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 2 }}>
            {AuthService.userRoleIncludes(["habitante"]) ? (
              <>
                <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
                  Comparte este enlace con tus huéspedes para que completen el formulario:
                </Typography>
                <Box sx={{ 
                  bgcolor: 'grey.100', 
                  p: 2, 
                  borderRadius: 1, 
                  width: '100%',
                  mb: 2,
                  wordBreak: 'break-all'
                }}>
                  <Typography variant="body2">
                    {`${window.location.origin}/guestform?id=${reservationId}`}
                  </Typography>
                </Box>
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<ContentCopyIcon />}
                  onClick={handleCopyLink}
                >
                  {linkCopied ? "¡Copiado!" : "Copiar Enlace"}
                </Button>
              </>
            ) : (
              <>
                <QRCodeSVG
                  value={`${window.location.origin}/guestform?id=${reservationId}`}
                  size={256}
                  level="L"
                />
                <Typography variant="caption" color="text.secondary" sx={{ mt: 2, textAlign: 'center' }}>
                  Solicita al huesped que escanea el QR para completar el formulario
                </Typography>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenQrModal(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEditModal}
        onClose={handleCloseEdit}
        aria-labelledby="edit-dialog-title"
      >
        <DialogTitle id="edit-dialog-title">
          {editField === 'checkin' ? 'Editar Check-in' : 
           editField === 'expires' ? 'Editar Check-out' : 
           'Editar Número de Huespedes'}
        </DialogTitle>
        <DialogContent>
          {editField === 'guest_number' ? (
            <TextField
              autoFocus
              margin="dense"
              label="Número de huespedes"
              type="number"
              fullWidth
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              inputProps={{ min: 1 }}
            />
          ) : (
            <TextField
              autoFocus
              margin="dense"
              label={editField === 'checkin' ? 'Fecha de Check-in' : 'Fecha de Check-out'}
              type="datetime-local"
              fullWidth
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit}>Cancelar</Button>
          <Button onClick={handleSaveEdit} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Mensaje copiado al portapapeles
        </Alert>
      </Snackbar>
      <BackgroundImage />
    </Container>
  );
};

export default AirbnbReservation;
