import * as React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import "../Amenities.css"

import BackgroundImage from '../components/Cityline';
import Fab from '@mui/material/Fab';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import CarCrashIcon from '@mui/icons-material/CarCrash';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import axios from 'axios';
import AppConfig from '../services/app-config';
import AuthService from '../services/auth.js';
import { CardHeader } from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { useSwipeable } from 'react-swipeable';
import { motion, AnimatePresence } from 'framer-motion';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';

import HotelIcon from '@mui/icons-material/Hotel';
import NightShelterIcon from '@mui/icons-material/NightShelter';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { QRCodeSVG } from 'qrcode.react';

import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Modal from '@mui/material/Modal';
import LoupeIcon from '@mui/icons-material/Loupe';

export default function ParkingShare({setNavbarState, navbarState}) {
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
  const [loading, setLoading] = React.useState(true);
  const [currentReservations, setCurrentReservations] = React.useState([]);
  const [pastReservations, setPastReservations] = React.useState([]);
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [tabValue, setTabValue] = React.useState('current');
  const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'success' });
  const [drawerShow, setDrawerShow] = React.useState(false);
  const [newReservationDateStart, setNewReservationDateStart] = React.useState(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString().split('T')[0];
  });
  
  const [newReservationDateEnd, setNewReservationDateEnd] = React.useState(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 3);
    return tomorrow.toISOString().split('T')[0];
  });
  const [newReservationGuestName, setNewReservationGuestName] = React.useState('');
  const [newReservationCheckIn, setNewReservationCheckIn] = React.useState(AppConfig.getf('reservationCheckIn', '15:00'));
  const [newReservationCheckOut, setNewReservationCheckOut] = React.useState(AppConfig.getf('reservationCheckOut', '13:00'));
  const [newReservatinGuests, setNewReservationGuests] = React.useState(AppConfig.getf('reservationGuests', '2'));

  const [currentDateTime, setCurrentDateTime] = React.useState('');
  const navigate = useNavigate();
  let tab = new URLSearchParams(location.search).get('tab');

  const apiUrl = process.env.REACT_APP_MGO_API_URL;
  const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    fetchInfo(newValue);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleClick = (route) => {
    setRedirect({ trigger: true, route: `/${route.toLowerCase()}` })
}

  const fabStyle = {
    position: 'fixed',
    bottom: 32,
    right: 32
  };

  const cardStyle = {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        },
    };

    const iconStyle = {
        position: 'absolute',
        right: -20,
        bottom: -20,
        fontSize: 105,
        color: 'rgba(0, 0, 0, 0.10)',
    };

  const fetchInfo = (currentTabValue) => {
    setShowBackdrop(true);
    setLoading(true);
    
    const endpoint = `airbnb/${currentTabValue}`;
    
    axios.get(`${apiUrl}/${endpoint}`)
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          const dataArray = Object.entries(response.data.data).map(([id, value]) => ({ id, ...value }));
          if (currentTabValue === 'current') {
            setCurrentReservations(dataArray.reverse());
          } else {
            setPastReservations(dataArray.reverse());
          }
          setShowBackdrop(false);
          setLoading(false);
        }
      })
      .catch(error => {
        console.error('Error fetching parking requests:', error);
        if (error.response && error.response.data && error.response.data.code === 401) {
          setRedirect({ trigger: true, route: '/login' })
        }
      });
  }

  React.useEffect(() => {
    setNavbarState({...navbarState, showBack: true, showOptions: false, showSearch: false})
    if (AuthService.userRoleIncludes(["habitante"]) && AuthService.getCurrentUserRole() !== 'superuser') {
      if (AppConfig.get('unit_mode') !== 'airbnb') {
        setRedirect({ trigger: true, route: '/' });
      }
    }
    if (tab !== null) { 
      setRedirect({ trigger: true, route: '/' });
      setTabValue(tab);
    }
    fetchInfo(tabValue);

  }, [tabValue]);

  const [swipeDirection, setSwipeDirection] = React.useState(null);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (tabValue === 'current') {
        setSwipeDirection('left');
        setTabValue('past');
      }
    },
    onSwipedRight: () => {
      if (tabValue === 'confirmed') {
        setSwipeDirection('right');
        setTabValue('current');
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const pageVariants = {
    enter: (direction) => {
      return {
        x: direction === 'left' ? 1000 : -1000,
        opacity: 0
      };
    },
    center: {
      x: 0,
      opacity: 1
    },
    exit: (direction) => {
      return {
        x: direction === 'left' ? -1000 : 1000,
        opacity: 0
      };
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

  const handleDrawerToggle = () => {
    setDrawerShow((prevState) => !prevState);
    // if (!drawerShow) {
    //   setNewReservationDateStart('');
    //   setNewReservationDateEnd('');
    // }
  };

  const handleNewRequest = () => {
    setDrawerShow(true);
  };

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const submitNewRequest = async () => {
    setIsSubmitting(true);
    try {
      if (!newReservationGuestName || !newReservationDateStart || !newReservationDateEnd || 
          !newReservationCheckIn || !newReservationCheckOut || !newReservatinGuests) {
        setSnackbar({ 
          open: true, 
          message: "Por favor, complete todos los campos requeridos", 
          severity: "warning" 
        });
        setIsSubmitting(false);
        return;
      }

      const checkinDateTime = new Date(`${newReservationDateStart}T${newReservationCheckIn}`);
      const checkoutDateTime = new Date(`${newReservationDateEnd}T${newReservationCheckOut}`);

      if (checkoutDateTime <= checkinDateTime) {
        setSnackbar({ 
          open: true, 
          message: "La fecha y hora de check-out debe ser posterior a la fecha y hora de check-in", 
          severity: "error" 
        });
        setIsSubmitting(false);
        return;
      }

      const payload = {
        main_guest: newReservationGuestName,
        guest_number: parseInt(newReservatinGuests),
        checkin_date: newReservationDateStart,
        checkin_hour: newReservationCheckIn,
        checkout_date: newReservationDateEnd,
        checkout_hour: newReservationCheckOut
      };

      axios.post(`${apiUrl}/airbnb/create/reservation`, payload)
        .then(response => {
          AppConfig.parseResponse(response);
          if (response.status === 200) {
            setSnackbar({ 
              open: true, 
              message: "Reservación registrada correctamente", 
              severity: "success" 
            });
            AppConfig.storeLocal('reservationCheckIn', newReservationCheckIn);
            AppConfig.storeLocal('reservationCheckOut', newReservationCheckOut);
            AppConfig.storeLocal('reservationGuests', newReservatinGuests);
            fetchInfo(tabValue);
            setDrawerShow(false);
            handleShareLink(response.data.data.id);
          }
        })
        .catch(error => {
          console.error('Error submitting Airbnb reservation:', error);
          if (error.response && error.response.data && error.response.data.code === 422) {
            setSnackbar({ 
              open: true, 
              message: error.response.data.msg, 
              severity: "error" 
            });
          } else {
            setSnackbar({ 
              open: true, 
              message: "Error al registrar la reservación", 
              severity: "error" 
            });
          }
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } catch (error) {
      console.error('Error submitting Airbnb reservation:', error);
      setIsSubmitting(false);
    }
  };

  const newRequestDrawer = (
    <Card sx={{ py: 3, px: 2, display: 'block', marginLeft: 'auto', marginRight: 'auto', borderTopLeftRadius: "20px", borderTopRightRadius: "20px", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px", maxWidth: "550px" }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ mb: 2 }}>
          Registrar Estadia
        </Typography>
        <TextField
          sx={{ mb: 2 }}
          fullWidth
          size="medium"
          value={newReservationDateStart}
          id='timeStart'
          label="Fecha Check-In"
          type="date"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            setNewReservationDateStart(e.target.value);
          }}
        />
        <TextField
          sx={{ mb: 4 }}
          fullWidth
          size="medium"
          value={newReservationCheckIn}
          id='checkin'
          label="Hora Check-In"
          type="time"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            setNewReservationCheckIn(e.target.value);
          }}
        />
        <TextField
          sx={{ mb: 2 }}
          fullWidth
          size="medium"
          value={newReservationDateEnd}
          id='timeEnd'
          label="Fecha Check-Out"
          type="date"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            setNewReservationDateEnd(e.target.value);
          }}
        />
        <TextField
          sx={{ mb: 4 }}
          fullWidth
          size="medium"
          value={newReservationCheckOut}
          id='checkout'
          label="Hora Check-Out"
          type="time"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            setNewReservationCheckOut(e.target.value);
          }}
        />
        <TextField
          sx={{ my: 2 }}
          fullWidth
          value={newReservationGuestName}
          onChange={(e) => {
            setNewReservationGuestName(AppConfig.titleCase(e.target.value));
          }}
          label="Nombre del Huesped Principal"
          variant="outlined"
        />

        <TextField
          sx={{ mb: 2 }}
          fullWidth
          type="number"
          value={newReservatinGuests}
          onChange={(e) => {
            setNewReservationGuests(e.target.value);
          }}
          label="Cantidad de Huespedes"
          variant="outlined"
          inputProps={{
            min: 1,
            max: 16
          }}
        />
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button size="medium" onClick={handleDrawerToggle} color="inherit" variant="text" sx={{ mr: 1 }}>Cancelar</Button>
        <Button 
          size="medium" 
          onClick={submitNewRequest} 
          color="secondary" 
          sx={{ px: 4 }} 
          variant="contained"
          disabled={isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
        >
          {isSubmitting ? 'Registrando...' : 'Registrar'}
        </Button>
      </CardActions>
    </Card>
  );

  const [shareLinkOpen, setShareLinkOpen] = React.useState(false);
  const [selectedReservationId, setSelectedReservationId] = React.useState(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleShareLink = (reservationId) => {
    setSelectedReservationId(reservationId);
    setShareLinkOpen(true);
  };

  const handleCloseShareLink = () => {
    setShareLinkOpen(false);
    setSelectedReservationId(null);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbarOpen(true);
  };

  const getGuestFormLink = (reservationId) => {
    return `${window.location.origin}/guestform?id=${reservationId}`;
  };

  const handleViewDetails = (requestId) => {
    navigate(`/solicitudparqueo?id=${requestId}`);
  };

  const [openQrModal, setOpenQrModal] = React.useState(false);
  const [currentReservationId, setCurrentReservationId] = React.useState(null);
  const [linkCopied, setLinkCopied] = React.useState(false);

  const handleCopyLink = (reservationId) => {
    const link = `${window.location.origin}/guestform?id=${reservationId}`;
    navigator.clipboard.writeText(link).then(() => {
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 2000);
    });
  };

  const handleShareClick = (reservationId) => {
    setCurrentReservationId(reservationId);
    setOpenQrModal(true);
  };

  return (
    <Container maxWidth="sm" {...swipeHandlers}>
      {redirect.trigger && (
        <Navigate to={redirect.route} replace={true} />
      )}
      <Box sx={{ mt: 1 }}>
        <Snackbar open={snackbar.open} autoHideDuration={8000} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
        <Backdrop open={showBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="parking tabs" variant="fullWidth" sx={{ mt: 0, mb: 0.2 }} >
          <Tab label="Proximas Estadias" value={'current'}/>
          <Tab label="Estadias Pasadas" value={'past'}/>
        </Tabs>


        <AnimatePresence initial={false} custom={swipeDirection}>
          <motion.div
            key={tabValue}
            custom={swipeDirection}
            variants={pageVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={pageTransition}
          >
            {tabValue === "past" ? (
              <>
                {!loading && (
                  pastReservations.length === 0 ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 100px)' }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Card variant="outlined" sx={{ border: 0, backgroundColor: 'transparent' }} style={{ borderRadius: '10px', textAlign: 'center', width: '100%', paddingTop: '30%', paddingBottom: '40%' }}>
                                <CardContent>
                            {/* <NightShelterIcon sx={{ fontSize: 80, color: 'text.secondary', mb: 2 }} /> */}
                            <img src="/airbnb-logo.png" alt="Airbnb" style={{ width: 80, height: 80, marginRight: 8 }} />
                            <Typography variant="h5" component="div" sx={{ mb: 2 }}><b>No hay estadias pasadas.</b></Typography>
                            <Divider />
                            <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>Aquí podrás ver las estadias pasadas de tu Airbnb.</Typography>
                            </CardContent>
                        </Card>
                      </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    <>
                      {pastReservations.map((reservation) => (
                        <React.Fragment key={reservation.id}>
                          <Card
                            variant="outlined"
                            sx={{ mb: 1.2, border: 0, mt: 1 }}
                          >
                            <CardContent>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 0 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <img src="/airbnb-logo.png" alt="Airbnb" style={{ width: 24, height: 24, marginRight: 8 }} />
                                  <Typography variant="h6" component="div">
                                    {reservation.main_guest}
                                  </Typography>
                                </Box>
                              </Box>
                              {AuthService.userRoleIncludes(["admin","receptor"]) && <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                                <Box>
                                  <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                    {AppConfig.get('unit_type')}
                                  </Typography>
                                  <Typography variant="body1">
                                    {reservation.unit}
                                  </Typography>
                                </Box>
                              </Box>}
                              <Box sx={{ display: 'flex', gap: 2, mb: 0 }}>
                                <Box>
                                  <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                    Ingreso
                                  </Typography>
                                  <Typography variant="body1">
                                    {AppConfig.formatFriendlyDate(reservation.checkin)}
                                  </Typography>
                                </Box>
                                
                                <Box sx={{ ml: 'auto' }}>
                                  <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                    Huespedes
                                  </Typography>
                                  <Typography variant="body1">
                                    {reservation.guest_number}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                                <Box>
                                  <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                    Salida
                                  </Typography>
                                  <Typography variant="body1">
                                    {AppConfig.formatFriendlyDate(reservation.expires)}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                <Chip 
                                  edge="end"
                                  aria-label="formulario"
                                  icon={ <CheckCircleIcon />}
                                  label="Reserva Finalizada"
                                  color="default"
                                  sx={{ pl: 1, pr: 1 }}
                                />
                              </Box>
                            </CardContent>
                            <CardActions sx={{ justifyContent: 'flex-end' }}>
                              <Button 
                                variant="outlined" 
                                color="secondary"
                                startIcon={<LoupeIcon />}
                                size="small"
                                fullWidth
                                sx={{ px: 2 }}
                                onClick={() => navigate(`/airbnb/reservacion/${reservation.id}`)}
                              >
                                Ver Detalles
                              </Button>
                            </CardActions>
                          </Card>
                          <Divider sx={{ mt: 1 }} />
                        </React.Fragment>
                      ))}
                    </>
                  )
                )}
              </>
            ) : (
              <>
                {!loading && (
                  currentReservations.length === 0 ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 100px)' }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Card variant="outlined" sx={{ border: 0, backgroundColor: 'transparent' }} style={{ borderRadius: '10px', textAlign: 'center', width: '100%', paddingTop: '30%', paddingBottom: '40%' }}>
                                <CardContent>
                            {/* <NightShelterIcon sx={{ fontSize: 80, color: 'text.secondary', mb: 2 }} /> */}
                            <img src="/airbnb-logo.png" alt="Airbnb" style={{ width: 80, height: 80, marginRight: 8 }} />
                            <Typography variant="h5" component="div" sx={{ mb: 2 }}><b>No tienes estadias programadas en el app</b></Typography>
                            <Divider />
                            <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>Si tienes una estadia registrala aqui para agilizar el ingreso de tus huespedes.</Typography>
                            </CardContent>
                        </Card>
                      </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    <>
                      {currentReservations.map((reservation) => (
                        <React.Fragment key={reservation.id}>
                          <Card
                            variant="outlined"
                            sx={{ mb: 1.2, border: 0, mt: 1 }}
                            // onClick={() => handleViewDetails(reservation.id)}
                          >
                            <CardContent>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 0 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <img src="/airbnb-logo.png" alt="Airbnb" style={{ width: 24, height: 24, marginRight: 8 }} />
                                  <Typography variant="h6" component="div">
                                    {reservation.main_guest}
                                  </Typography>
                                </Box>
                                
                              </Box>
                              {AuthService.userRoleIncludes(["admin","receptor"]) && <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                                <Box>
                                  <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                    {AppConfig.get('unit_type')}
                                  </Typography>
                                  <Typography variant="body1">
                                    {reservation.unit}
                                  </Typography>
                                </Box>
                              </Box>}
                              <Box sx={{ display: 'flex', gap: 2, mb: 0 }}>
                                <Box>
                                  <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                    Ingreso
                                  </Typography>
                                  <Typography variant="body1">
                                    {AppConfig.formatFriendlyDate(reservation.checkin)}
                                  </Typography>
                                </Box>
                                
                                <Box sx={{ ml: 'auto' }}>
                                  <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                    Huespedes
                                  </Typography>
                                  <Typography variant="body1">
                                    {reservation.guest_number}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                              <Box>
                                  <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                    Salida
                                  </Typography>
                                  <Typography variant="body1">
                                    {AppConfig.formatFriendlyDate(reservation.expires)}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                <Chip 
                                  edge="end"
                                  aria-label="formulario"
                                  icon={reservation.guest_checked_in ? <CheckCircleIcon style={{ color: 'green' }} /> : reservation.info_submitted ? <CheckCircleIcon style={{ color: 'green' }} /> : <AutorenewIcon />}
                                  label={reservation.guest_checked_in ? "Huespedes Ingresados" : reservation.info_submitted ? 'Formulario Completado' : 'Formulario Pendiente'}
                                  color="default"
                                  sx={{ pl: 1, pr: 1 }}
                                />
                                 {!reservation.info_submitted && (
                                <Chip
                                  edge="end"
                                  aria-label="formulario enlace"
                                  icon={AuthService.getCurrentUserRole() === "habitante" ? <ShareIcon /> : <QrCodeIcon />}
                                  label="Enlance"
                                  color='primary'
                                  sx={{ ml: -2}}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleShareClick(reservation.id);
                                  }}
                                />)}
                                
                              </Box>
                            </CardContent>
                            <CardActions sx={{ justifyContent: 'flex-end' }}>
                              <Button 
                                variant="outlined" 
                                color="secondary"
                                startIcon={<LoupeIcon />}
                                size="small"
                                fullWidth
                                sx={{ px: 2 }}
                                onClick={() => navigate(`/airbnb/reservacion/${reservation.id}`)}
                              >
                                Ver Detalles
                              </Button>
                            </CardActions>
                          </Card>
                          <Divider sx={{ mt: 1 }} />
                        </React.Fragment>
                      ))}
                    </>
                  )
                )}
              </>
            )}
          </motion.div>
        </AnimatePresence>
      </Box>
      <Drawer
        anchor="bottom"
        open={drawerShow}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
        sx={{
          display: { xs: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '440' },
        }}
      >
        {newRequestDrawer}
      </Drawer>
      <Modal
        open={shareLinkOpen}
        onClose={handleCloseShareLink}
        aria-labelledby="share-link-modal"
        aria-describedby="modal-to-share-guest-form-link"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: 500 },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Compartir Enlace con Huesped
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            Comparte este enlace con tu huesped de Airbnb para que complete el formulario de registro antes de su llegada.
          </Typography>
          
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
            <TextField
              fullWidth
              value={selectedReservationId ? getGuestFormLink(selectedReservationId) : ''}
              InputProps={{
                readOnly: true,
              }}
              size="small"
            />
            <IconButton 
              color="primary"
              onClick={() => copyToClipboard(getGuestFormLink(selectedReservationId))}
              sx={{ minWidth: 'auto' }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Box>
          
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleCloseShareLink} color="primary">
              Cerrar
            </Button>
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Link copiado al portapapeles"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
      <Dialog
        open={openQrModal}
        onClose={() => setOpenQrModal(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 2 }}>
            {AuthService.userRoleIncludes(["habitante"]) ? (
              <>
                <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
                  Comparte este enlace con tu huesped de Airbnb para que complete el formulario de registro antes de su llegada.
                </Typography>
                <Box sx={{ 
                  bgcolor: 'grey.100', 
                  p: 2, 
                  borderRadius: 1, 
                  width: '100%',
                  mb: 2,
                  wordBreak: 'break-all'
                }}>
                  <Typography variant="body2">
                    {`${window.location.origin}/guestform?id=${currentReservationId}`}
                  </Typography>
                </Box>
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<ContentCopyIcon />}
                  onClick={() => handleCopyLink(currentReservationId)}
                >
                  {linkCopied ? "¡Copiado!" : "Copiar Enlace"}
                </Button>
              </>
            ) : (
              <>
                <QRCodeSVG
                  value={`${window.location.origin}/guestform?id=${currentReservationId}`}
                  size={256}
                  level="L"
                />
                <Typography variant="caption" color="text.secondary" sx={{ mt: 2, textAlign: 'center' }}>
                  Solicita al huesped que escanea el QR para completar el formulario
                </Typography>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenQrModal(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      {AuthService.userRoleIncludes(["habitante"]) && <Box sx={{ m: 1, display: 'flex', justifyContent: 'center' }}>
        <Fab
          sx={fabStyle}
          variant="extended"
          color="secondary"
          size="large"
          aria-label="add"
          onClick={handleNewRequest}
        >
          <HotelIcon sx={{ mr: 1 }} />
          Registrar Estadia
        </Fab>
      </Box>}
      <BackgroundImage />
    </Container>
  );
}